import React from "react";
import parse from 'html-react-parser';

const CableCrimper: React.FC = () => {

  return (
    <div>
      {parse(`
<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=solardwarforc-20&language=en_US&marketplace=amazon&region=US&placement=B07WNVSMPN&asins=B07WNVSMPN&linkId=e2510a91649142fba4b226e8bc337053&show_border=true&link_opens_in_new_window=true"></iframe>
  `)}
    </div>
  )

};

export default CableCrimper;
